<template>
	<div class="poolMore" style="display: flex;
    flex-wrap: wrap;">
		<!-- <el-row :gutter="20">
			<el-col :span="8"> -->
		


			<div style="margin-top: 10px;margin-right: 20px;flex:30%;" v-for="(item,index) in data"  :key="index">
				<div class="title-box"><span class="m-title">{{"流量使用(GB)-"+item.accountName}}</span></div>
				<div class="content-box">
					<el-row>
						<el-col :span="24">
							<div style="width:100%; height: 300px" :id="'pie_'+item.accountId"></div>
						</el-col>
					</el-row>
					<el-row class="rowone">
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="rechargeAmountTotal">{{(item.flowSizeTotal/1024).toFixed(0)}}</p>
							<p>总流量</p>
						</el-col>
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="rechargeAmountThisMonth">{{(item.flowSizeUsed/1024).toFixed(0)}}</p>
							<p>已用流量</p>
						</el-col>
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="rechargeAmountPreMonth">{{(item.flowSizeRemaining/1024).toFixed(0)}}</p>
							<p>剩余流量</p>
						</el-col>

					</el-row>
					<el-row class="rowone">
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="">{{item.cardCountTotal}}</p>
							<p>总网卡数</p>
						</el-col>
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="">{{item.cardCountChanrged||0}}</p>
							<p>已充值网卡数</p>
						</el-col>
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="">{{item.effectCountThisMonth||0}}</p>
							<p>本月生效网卡数</p>
						</el-col>

					</el-row>
					<el-row class="rowone">
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="">{{item.platFlow||0}}</p>
							<p>平台流量</p>
						</el-col>
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="">{{item.extraMealFlow||0}}</p>
							<p>加餐流量</p>
						</el-col>
						<el-col :span="8" class="col-flex">
							<p style="color: #09A2E3" id="">{{item.expiredCountThisMonth||0}}</p>
							<p>本月过期网卡数</p>
						</el-col>

					</el-row>
				</div>
			</div>
		
	</div>
</template>

<script>
	import echarts from "echarts"
	import {
		list4pool
	} from "@/api/welcome.js"
	export default {
		data() {
			return {
				data: {},
				myChart:[],
			}
		},
		created() {
			this.getlist4pool()
		},
		mounted() {
    window.onresize = ()=>{
			return (()=>{
				for(let item of this.myChart){
					if(item){
						item.resize()
					}
				}
			}
			)()
		}
		},
		methods: {
			getlist4pool() {
				list4pool({
					limit: 999
				}).then(res => {
					this.data = res.list
					this.$nextTick(()=>{
						this.loadpie()
					})
					
				})

			},
			loadpie() {
				for (let i  in this.data) {
					let item =  this.data[i]
					let pieId = 'pie_' + item.accountId
					let dom = document.getElementById(pieId);
					let myChart = echarts.init(dom);
          this.myChart[i] = myChart
					let option = {
						tooltip: {
							trigger: 'item',
							formatter: "{a} <br/>{b}: {c} ({d}%)"
						},
						legend: {
							orient: 'vertical',
							x: 'left',
							data: ['剩余流量', '已用流量']
						},
						series: [{
							color: ["#97c407", "#5ebbdd"],
							name: "流量使用(GB)-" + item.accountName,
							type: 'pie',
							radius: ['50%', '70%'],
							avoidLabelOverlap: false,
							label: {
								normal: {
									show: false,
									position: 'center'
								},
								emphasis: {
									show: true,
									textStyle: {
										fontSize: '14',
										fontWeight: 'bold'
									}
								}
							},
							labelLine: {
								normal: {
									show: false
								}
							},
							data: [{
									value: (item.flowSizeRemaining / 1024).toFixed(0),
									name: '剩余流量'
								},
								{
									value: (item.flowSizeUsed / 1024).toFixed(0),
									name: '已用流量'
								}
							]
						}]
					};

					if (option && typeof option === "object") {
						myChart.setOption(option, true);
					}
				}


			},
		}
	}
</script>

<style>
</style>
